import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, Grow, InputLabel, Link, MenuItem, Paper as MuiPaper, Select, Typography } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Activity, Upload } from "react-feather";

import { spacing } from "assets/styles/spacing";
import { SelectValues } from "enums/select.enums";
import { ImportDataType } from "enums/type.enums";
import Dropzone from "components/dropzone/Dropzone";
import actions from "store/actions";
import api from "api";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import ButtonLoading from "components/buttons/ButtonLoading";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { ServerSuccesses } from "enums/server.enums";
import { RootState } from "store/types";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";

const Paper = muiStyled(MuiPaper)({
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  margin: 6,
  flex: 1,
});

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacing(2)}px;
`;

const SelectDataType = styled(Select)`
  min-width: 250px;
`;

const LinkTemplate = muiStyled(Link)({
  cursor: "pointer",
});

const ButtonsContainer = muiStyled(Grid)({
  "& > div": {
    margin: spacing(3),
  },
});

interface Props {
  challengeId: string;
  setPreviewResult: (preview: any) => void;
}

const ImportsUploadInputSide: React.FC<Props> = ({ challengeId, setPreviewResult }) => {
  const dispatch = useDispatch();
  const isAuthModeOKTA = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) === AuthMode.OKTA;
  const [dataType, setDataType] = useState<string>();
  const [formData, setFormData] = useState<FormData>();
  const [importErrors, setImportErrors] = useState<string[]>([]);
  const [isFileValid, setIsFileValid] = useState(false);
  /* analyze file */
  const [isLoadingAnalyzeCatalog, analyzeCatalogWithSnackbar] = useSnackbarPromiseHandler(api.imports.analyzeCatalog);
  const [isLoadingAnalyzeMedia, analyzeMediaWithSnackbar] = useSnackbarPromiseHandler(api.imports.analyzeMedia);
  const [isLoadingAnalyzeResults, analyzeResultsWithSnackbar] = useSnackbarPromiseHandler(api.imports.analyzeResults);
  const [isLoadingAnalyzeUsers, analyzeUsersWithSnackbar] = useSnackbarPromiseHandler(api.imports.analyzeUsers);
  /* import real data */
  const [isLoadingImportCatalog, importCatalogWithSnackbar] = useSnackbarPromiseHandler(
    api.imports.catalog,
    `server.success.${ServerSuccesses.UPLOAD_FILE_SUCCESS}`
  );
  const [isLoadingImportMedia, importMediaWithSnackbar] = useSnackbarPromiseHandler(
    api.imports.media,
    `server.success.${ServerSuccesses.UPLOAD_FILE_SUCCESS}`
  );
  const [isLoadingImportResults, importResultsWithSnackbar] = useSnackbarPromiseHandler(
    api.imports.results,
    `server.success.${ServerSuccesses.UPLOAD_FILE_SUCCESS}`
  );
  const [isLoadingImportUsers, importUsersWithSnackbar] = useSnackbarPromiseHandler(
    api.imports.users,
    `server.success.${ServerSuccesses.UPLOAD_FILE_SUCCESS}`
  );

  const websocket = useSelector((state: RootState) => state.websocket);
  const { status = "" } = websocket || {};

  /* export samples */
  const [isLoadingExportCatalogSample, exportCatalogSampleWithSnackbar] = useSnackbarPromiseHandler(api.exports.sampleCatalog);
  const [isLoadingExportResultsSample, exportResultsSampleWithSnackbar] = useSnackbarPromiseHandler(api.exports.sampleResults);
  const [isLoadingExportUsersSample, exportUsersSampleWithSnackbar] = useSnackbarPromiseHandler(api.exports.sampleUsers);

  const onDownloadTemplate = async () => {
    let result;

    switch (dataType) {
      case ImportDataType.RESULTS:
        result = await exportResultsSampleWithSnackbar(challengeId);
        break;
      case ImportDataType.CONTACT:
        result = await exportUsersSampleWithSnackbar(challengeId);
        break;
      case ImportDataType.STORE_ITEMS:
        result = await exportCatalogSampleWithSnackbar(challengeId);
        break;
      default:
        return;
    }

    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${dataType}-template.xlsx`);
    link.click();
  };

  const onImport = async () => {
    switch (dataType) {
      case ImportDataType.RESULTS:
        await importResultsWithSnackbar(challengeId, formData);
        break;
      case ImportDataType.CONTACT:
        await importUsersWithSnackbar(challengeId, formData);
        break;
      case ImportDataType.STORE_ITEMS:
        await importCatalogWithSnackbar(challengeId, formData);
        break;
      case ImportDataType.MEDIA:
        await importMediaWithSnackbar(challengeId, formData);
        break;
      default:
    }
  };

  const onAnalyze = async () => {
    setImportErrors([]);
    setIsFileValid(false);
    setPreviewResult(undefined);
    let result;

    switch (dataType) {
      case ImportDataType.RESULTS:
        result = await analyzeResultsWithSnackbar(challengeId, formData);
        break;
      case ImportDataType.CONTACT:
        result = await analyzeUsersWithSnackbar(challengeId, formData);
        break;
      case ImportDataType.STORE_ITEMS:
        result = await analyzeCatalogWithSnackbar(challengeId, formData);
        break;
      case ImportDataType.MEDIA:
        result = await analyzeMediaWithSnackbar(challengeId, formData);
        break;
      default:
        return;
    }

    setPreviewResult(result.data);

    if (result.data.nbErrors > 0) {
      setImportErrors(result.data.errors);
    } else {
      setIsFileValid(true);
    }
  };

  const onChangeFile = (files: any[]) => {
    const file = files?.[0];

    setIsFileValid(false);
    setFormData(undefined);

    if (file) {
      const newFormData = new FormData();

      newFormData.append("file", file);
      setFormData(newFormData);
    }
  };

  const handleChangeDataType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIsFileValid(false);
    setDataType(event.target.value as SelectValues | ImportDataType);
  };

  // WEBSOCKET SUBSCRIBE/UNSUBSCRIBE
  useEffect(() => {
    (async function listenImport() {
      try {
        await dispatch(actions.websocket.listenImport());
      } catch (error) {
        // console.error("Error listen import:", error);
      }
    })();

    return () => {
      (async function stopListenImport() {
        try {
          await actions.websocket.removeListenerImport();
        } catch (error) {
          // console.error("Error stop listen import :", error);
        }
      })();
    };
  }, [dispatch]);

  const isLoading = isLoadingImportUsers || isLoadingImportResults || isLoadingImportCatalog || isLoadingImportMedia;

  return (
    <>
      {(isLoadingExportResultsSample || isLoadingExportUsersSample || isLoadingExportCatalogSample) && <AbsoluteLoader />}
      <Grid item container xs={6}>
        <Grid item container xs={12} direction="column">
          <Grow in>
            <Paper>
              <Title>
                <Typography variant="h4">
                  <FormattedMessage id="importation.upload.inputSide.title.filename" />
                </Typography>
              </Title>
              <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={3}>
                <FormControl variant="outlined">
                  <InputLabel id="select-data-type-label">
                    <FormattedMessage id="form.imports.select.dataType" />
                  </InputLabel>
                  <SelectDataType
                    labelId="select-data-type-label"
                    value={dataType}
                    onChange={handleChangeDataType}
                    label={<FormattedMessage id="form.imports.select.dataType" />}
                  >
                    {Object.values(ImportDataType)
                      // only allow STORE importation for CLUB user
                      .filter(
                        (importDataType) => isAuthModeOKTA || [ImportDataType.STORE_ITEMS, ImportDataType.MEDIA].includes(importDataType)
                      )
                      .map((importDataType: ImportDataType) => (
                        <MenuItem value={importDataType} key={importDataType}>
                          <FormattedMessage id={`form.imports.select.dataType.${importDataType}`} />
                        </MenuItem>
                      ))}
                  </SelectDataType>
                </FormControl>
                {dataType &&
                  (dataType === ImportDataType.MEDIA ? (
                    <Typography variant="body2">
                      <FormattedMessage id="importation.export.media.extensions" />
                    </Typography>
                  ) : (
                    <LinkTemplate onClick={onDownloadTemplate}>
                      <FormattedMessage id="common.link.download.template" />
                    </LinkTemplate>
                  ))}
              </Box>
            </Paper>
          </Grow>
        </Grid>
        <Grid item container xs={12} direction="column">
          <Grow in>
            <Paper>
              <Dropzone name="fileInput" onChange={onChangeFile} minHeight={200} accept=".xlsx, .zip" />
            </Paper>
          </Grow>
        </Grid>
        <ButtonsContainer item container xs={12} justify="flex-end" alignItems="center">
          {isLoading && !!status && (
            <Typography variant="body2" style={{ color: "white" }}>
              <FormattedMessage id={`importation.ws.${status}`} />
            </Typography>
          )}
          <ButtonLoading
            variant="contained"
            color="primary"
            startIcon={<Activity />}
            onClick={onAnalyze}
            isLoading={isLoadingAnalyzeUsers || isLoadingAnalyzeResults || isLoadingAnalyzeCatalog || isLoadingAnalyzeMedia}
            disabled={!formData || !dataType}
          >
            <FormattedMessage id="importation.upload.button.analyze" />
          </ButtonLoading>
          <ButtonLoading
            variant="contained"
            color="primary"
            startIcon={<Upload />}
            onClick={onImport}
            isLoading={isLoading}
            disabled={!formData || !isFileValid || importErrors.length > 0}
          >
            <FormattedMessage id="importation.upload.button.import" />
          </ButtonLoading>
        </ButtonsContainer>
      </Grid>
    </>
  );
};

export default ImportsUploadInputSide;

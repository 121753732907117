const API_BASE_HOSTNAME = process.env.REACT_APP_API_BASE_HOSTNAME || "https://clubdes100.rvo.fr";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://clubdes100.rvo.fr/api/club";
const ROUTER_BASE_URL = process.env.ROUTER_BASE_URL || "/club";

const ENV = process.env.REACT_APP_ENV || "PRD";

const DEV_TOKEN = "";

const WEBSOCKET_ENDPOINT = process.env.REACT_APP_WEBSOCKET_ENDPOINT || "https://clubdes100.rvo.fr/websocket";

const RECAPTCHA_KEY = "6LdEHuIUAAAAAH1VnewKJ_t0QT79Qq69gIeeWbxN";

const ACR_VALUES_ARCA = process.env.REACT_APP_ACR_VALUES || "secure/name/x509-kerb-form/uri";
const CLIENT_ID_ARCA = process.env.REACT_APP_CLIENT_ID_ARCA || "c2d1fd2b-2f85-4672-b9c2-4993ab227255";
const REDIRECT_URI = "https://clubdes100.rvo.fr/club";
const REQUESTED_SCOPES_ARCA = process.env.REACT_APP_REQUESTED_SCOPES || "role arcaX role-vvo-irn70754";

const OKTA_CONFIG = {
  REDIRECT_URI: "https://clubdes100.rvo.fr/club/login",
  AUTHORIZATION_ENDPOINT: "https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/authorize",
  TOKEN_ENDPOINT: "https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/token",
  CLIENT_ID: "irn-70754_ope_native_hhp5yvcz9rpv",
  REQUESTED_SCOPES: "alliance_profile openid",
};

export {
  ENV,
  API_BASE_HOSTNAME,
  ROUTER_BASE_URL,
  API_BASE_URL,
  DEV_TOKEN,
  WEBSOCKET_ENDPOINT,
  RECAPTCHA_KEY,
  ACR_VALUES_ARCA,
  CLIENT_ID_ARCA,
  REDIRECT_URI,
  REQUESTED_SCOPES_ARCA,
  OKTA_CONFIG,
};

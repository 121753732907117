import jwt_decode from "jwt-decode";
import * as ENV from "config/env";
import { AuthMode } from "enums/api.enums";
import { OKTA_CONFIG } from "config/env";
import log from "loglevel";
import { LOCAL_STORAGE } from "./authentication-constants.utils";
import { ParamsAccessToken } from "../types/api.types";

declare global {
  interface Window {
    __UPDATING_AUTHENTICATION__: boolean;
  }
}

/* const TIMEOUT_ERROR = { error: "timeout", error_description: "Timeout" }; */

const sha256 = (plain: string): Promise<ArrayBuffer> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
};

const base64urlencode = (str: ArrayBuffer): string =>
  btoa(String.fromCharCode.apply(null, new Uint8Array(str) as unknown as Array<number>))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

const generateRandomString = (): string => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => `0${dec.toString(16)}`.substr(-2)).join("");
};

const pkceChallengeFromVerifier = async (v: string): Promise<string> => {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
};

export const isInIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const generateLoginUrl = async (silent = false): Promise<string> => {
  const state = generateRandomString();
  localStorage.setItem(LOCAL_STORAGE.PKCE, state);

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  const codeVerifier = generateRandomString();
  localStorage.setItem(LOCAL_STORAGE.PKCE_VERIFIER, codeVerifier);

  // Hash and base64-urlencode the secret to use as the challenge
  const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);

  let params: any = {
    response_type: "code",
    client_id: encodeURIComponent(OKTA_CONFIG.CLIENT_ID),
    state: encodeURIComponent(state),
    scope: encodeURIComponent(OKTA_CONFIG.REQUESTED_SCOPES),
    acr_values: ENV.ACR_VALUES_ARCA,
    redirect_uri: encodeURIComponent(OKTA_CONFIG.REDIRECT_URI),
    code_challenge: encodeURIComponent(codeChallenge),
    code_challenge_method: "S256",
  };

  if (silent) {
    params = { ...params, prompt: "none", response_mode: "web_message" };
  }

  const parameters = Object.keys(params)
    .map((key) => (params[key] ? `${key}=${params[key]}` : ""))
    .join("&");

  return `${OKTA_CONFIG.AUTHORIZATION_ENDPOINT}?${parameters}`;
};

export const getAccessTokenRequest = (
  params: ParamsAccessToken,
  success: (request: XMLHttpRequest, body: any) => void,
  error: (request: XMLHttpRequest, error: any) => void
) => {
  const request = new XMLHttpRequest();
  request.open("POST", ENV.OKTA_CONFIG.TOKEN_ENDPOINT, true);
  request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");

  request.onload = function onLoad() {
    let body = {};
    try {
      body = JSON.parse(request.response);
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.error(e);
    }

    if (request.status === 200) {
      success(request, body);
    } else {
      error(request, body);
    }
  };

  request.onerror = function onError() {
    error(request, {});
  };

  const body = Object.keys(params)
    .map((key) => (params[key as keyof typeof params] ? `${key}=${params[key as keyof typeof params]}` : ""))
    .join("&");

  request.send(body);
};

export function getAccessToken(
  code: string,
  success: (request: XMLHttpRequest, body: any) => void,
  error: (request: XMLHttpRequest, error: any) => void
) {
  const params = {
    code,
    grant_type: "authorization_code",
    code_verifier: localStorage.getItem(LOCAL_STORAGE.PKCE_VERIFIER),
    client_id: encodeURIComponent(OKTA_CONFIG.CLIENT_ID),
    redirect_uri: OKTA_CONFIG.REDIRECT_URI,
  };

  getAccessTokenRequest(params, success, error);
}

/*
export const getTokenInformations = (): Record<string, string> => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE.API_TOKEN) || "";
  const decodedToken = window.atob(accessToken.split(".")[1] || "") || "{}";
  return JSON.parse(decodedToken);
};
*/

/*
const runIframe = (authorizeUrl: string, eventOrigin: string): Promise<any> =>
  new Promise((res, rej) => {
    const iframe = window.document.createElement("iframe");
    iframe.setAttribute("width", "0");
    iframe.setAttribute("name", "authentication-frame");
    iframe.setAttribute("height", "0");
    iframe.style.display = "none";

    const timeoutSetTimeoutId = setTimeout(() => {
      rej(TIMEOUT_ERROR);
      window.document.body.removeChild(iframe);
    }, 60 * 1000);

    const iframeEventHandler = function iframeHandler(e: any) {
      if (e.origin !== eventOrigin) return;
      if (!e.data) return;

      e.source.close();
      if (e.data.error) {
        rej(e.data);
      } else {
        res(e.data);
      }
      clearTimeout(timeoutSetTimeoutId);
      window.removeEventListener("message", iframeEventHandler, false);
      if (window.document.body) {
        window.document.body.removeChild(iframe);
      }
    };

    window.addEventListener("message", iframeEventHandler, false);
    window.document.body.appendChild(iframe);
    iframe.setAttribute("src", authorizeUrl);
  });
*/

/*
const updateToken = async (): Promise<boolean> => {
  if (!window.__UPDATING_AUTHENTICATION__) {
    try {
      window.__UPDATING_AUTHENTICATION__ = true;
      const url = await generateLoginUrl(true);
      const response = await runIframe(url, window.location.origin);
      const { access_token: accessToken } = response;
      localStorage.setItem(LOCAL_STORAGE.API_TOKEN, `Bearer ${accessToken}`);
      return accessToken;
    } catch (e) {
      return false;
    } finally {
      window.__UPDATING_AUTHENTICATION__ = false;
    }
  }

  return true;
};
*/

export const isTokenxpired = (authMode: AuthMode, token?: string | null): boolean => {
  if (!token) {
    return true;
  }

  const { exp } = jwt_decode(token) as any;

  const dateThreshold = authMode === AuthMode.OKTA ? parseInt(exp, 10) * 1000 : parseInt(exp, 10) * 1000 - 60 * 10 * 1000;

  return dateThreshold < new Date().getTime();
};

const refreshAccessToken = (
  success: (request: XMLHttpRequest, body: any) => void,
  authMode: AuthMode,
  error: (request: XMLHttpRequest, error: any) => void
) => {
  const params = {
    grant_type: "refresh_token",
    refresh_token: localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN),
    client_id: encodeURIComponent(OKTA_CONFIG.CLIENT_ID),
    scope: encodeURIComponent(OKTA_CONFIG.REQUESTED_SCOPES),
    redirect_uri: OKTA_CONFIG.REDIRECT_URI,
  };

  getAccessTokenRequest(params, success, error);
};

export async function updateToken() {
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) as AuthMode;
  const refreshToken = localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);

  if (!window.__UPDATING_AUTHENTICATION__ && !!refreshToken) {
    window.__UPDATING_AUTHENTICATION__ = true;
    log.debug("JWT TOKEN Renewal", authMode);

    refreshAccessToken(
      (request, body) => {
        const accessToken = body.id_token;
        localStorage.setItem(LOCAL_STORAGE.API_TOKEN, accessToken);
        window.__UPDATING_AUTHENTICATION__ = false;
      },
      authMode,
      (request, error) => {
        log.error("Error while renewing JWT Token", error.error);
        window.__UPDATING_AUTHENTICATION__ = true;
      }
    );

    log.debug("JWT TOKEN Renewal Success", authMode);
  }
}

export async function getTokenSilently(): Promise<any> {
  const jwt = localStorage.getItem(LOCAL_STORAGE.API_TOKEN);
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) as AuthMode;

  if (ENV.ENV === "DEV" && authMode !== AuthMode.OKTA) return jwt;

  if (isTokenxpired(authMode, jwt)) {
    await updateToken();
  }

  return jwt;
}

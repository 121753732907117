const auth = {
  "screen.auth.title": "Bienvenue sur le site du club des 100",
  "screen.auth.okta.title": "Je m'authentifie avec Okta",
  "screen.auth.okta.button": "Connexion avec",
  "screen.auth.form.title": "Je m'authentifie avec mes identifiants",
  "screen.auth.form.forgottenPassword": "Mot de passe oublié ?",
  "screen.auth.form.forgottenPassword.modal.title": "Vous avez oublié votre mot de passe ?",
  "screen.auth.form.forgottenPassword.modal.body":
    "Aucun problème, saisissez l'adresse mail de votre compte et nous vous enverrons un mail de réinitialisation.",
  "screen.auth.form.email.label": "Email",
  "screen.auth.form.password.label": "Mot de passe",
  "screen.auth.login.button": "Se connecter",
  "screen.activation.form.title": "Réinitialiser mon mot de passe",
  "screen.activation.form.field.password.label": "Nouveau mot de passe",
  "screen.activation.form.field.repeatPassword.label": "Confirmation mot de passe",
};

export default auth;

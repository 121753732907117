import React, { useState } from "react";
import { styled as muiStyled } from "@material-ui/core/styles";
import { Button, Container as MuiContainer, Grid, Grow, Paper as MuiPaper, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import * as queryString from "query-string";

import { spacing } from "assets/styles/spacing";
import { colors } from "assets/styles/colors";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";

import OktaLogo from "assets/icons/OktaLogo";
import logoOkta from "assets/images/okta_logo.png";
import OktaAuthenticator from "components/authentication/OktaAuthenticator";
import AuthForm from "./AuthForm";

const Container = muiStyled(MuiContainer)({
  flex: 1,
});

const Paper = muiStyled(MuiPaper)({
  "&:focus": {
    outline: "none",
  },
  position: "relative",
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  flex: 1,
});

const TitlePage = muiStyled(Typography)({
  margin: spacing(10),
  color: colors.lightGrey,
});

const ContainerBlock = styled.div`
  // get priority over MUI styling
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    & > * {
      margin: ${spacing(6)}px;
    }
  }
`;

const Authentication: React.FC = () => {
  const [okta, setOkta] = useState(false);
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE);
  const authToken = localStorage.getItem(LOCAL_STORAGE.API_TOKEN);
  const location = useLocation();
  const { code } = queryString.parse(location.search);

  return (
    <Container>
      <Grid container item xs={12} justify="center">
        <TitlePage variant="h3">
          <FormattedMessage id="screen.auth.title" />
        </TitlePage>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item container xs={6}>
          <Grid item container xs={10}>
            <Grow in>
              <Paper>
                <ContainerBlock>
                  <Typography>
                    <FormattedMessage id="screen.auth.okta.title" />
                  </Typography>
                  {okta || (authMode === AuthMode.OKTA && authToken) || code ? <OktaAuthenticator /> : <OktaLogo />}
                  <Button variant="contained" color="primary" onClick={() => setOkta(true)}>
                    <span style={{ marginTop: "3px", display: "inline-block" }}>
                      <FormattedMessage id="screen.auth.okta.button" />
                    </span>
                    <img
                      src={logoOkta}
                      style={{
                        height: "100%",
                        objectFit: "contain",
                        maxHeight: 32,
                        marginLeft: 6,
                      }}
                      alt="OKTA"
                    />
                  </Button>
                </ContainerBlock>
              </Paper>
            </Grow>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item container xs={10}>
            <Grow in>
              <Paper>
                <AuthForm />
              </Paper>
            </Grow>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Authentication;

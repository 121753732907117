import React, { useState } from "react";
import styled from "styled-components";
import { BarChart2, CheckCircle, Edit3, Trash2 } from "react-feather";
import { Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { spacing } from "assets/styles/spacing";
import { QuestType } from "types/common.types";
import { colors } from "assets/styles/colors";
import Modal from "components/modal/Modal";
import ConfirmationModal from "components/modal/ConfirmationModal";
import actions from "store/actions";
import { RootState } from "store/types";
import { QuestFormType } from "types/form.types";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";
import { QuestGoalType } from "enums/type.enums";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";

import QuestForm from "./QuestForm";

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Quest = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuestTitle = styled.span`
  font-size: 16px;
  display: flex;
  align-items: center;

  &:hover {
    color: ${colors.yellowRenault};
  }

  & > svg {
    margin-right: ${spacing(2)}px;
  }
`;

const QuestsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  & > div {
    margin: ${spacing(2)}px 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const IconContainer = styled.span`
  cursor: pointer;
  margin: 0 8px;

  &:hover > svg {
    stroke: ${colors.yellowRenault};
  }
`;

interface Props {
  quests: Array<QuestType>;
}

const QuestBlock: React.FunctionComponent<Props> = ({ quests }) => {
  const [isOpenModalQuestForm, setIsOpenModalQuestForm] = useState<boolean>(false);
  const [isOpenModalConfirmDelete, setIsOpenModalConfirmDelete] = useState<boolean>(false);
  const [quest, setQuest] = useState<QuestType>(undefined);

  const dispatch = useDispatch();
  const intl = useIntl();
  const challenge = useSelector((state: RootState) => state.challenge.challenge);
  const { id } = challenge || {};
  const titleModalQuestForm = quest ? "form.quest.title.edit" : "form.quest.title.create";
  const isAuthModeOKTA = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) === AuthMode.OKTA;

  const createQuest = (challengeId: string, form: QuestFormType) => dispatch(actions.challenge.createQuest(challengeId, form));
  const editQuest = (challengeId: string, questId: string, form: QuestFormType) =>
    dispatch(actions.challenge.editQuest(challengeId, questId, form));
  const deleteQuest = (challengeId: string, questId: string) => dispatch(actions.challenge.deleteQuest(challengeId, questId));

  const [isLoadingCreateQuest, createQuestWithSnackbar] = useSnackbarPromiseHandler(
    createQuest,
    `server.success.${ServerSuccesses.CREATE_SUCCESS}`
  );
  const [isLoadingEditQuest, editQuestWithSnackbar] = useSnackbarPromiseHandler(
    editQuest,
    `server.success.${ServerSuccesses.EDIT_SUCCESS}`
  );
  const [isLoadingDeleteQuest, deleteQuestWithSnackbar] = useSnackbarPromiseHandler(
    deleteQuest,
    `server.success.${ServerSuccesses.DELETE_SUCCESS}`
  );

  const onCreateQuest = async (form: QuestFormType) => {
    try {
      await createQuestWithSnackbar(id, form);
    } finally {
      setIsOpenModalQuestForm(false);
    }
  };

  const onEditQuest = async (form: QuestFormType) => {
    try {
      await editQuestWithSnackbar(id, quest.id, form);
    } finally {
      setIsOpenModalQuestForm(false);
    }
  };

  const onDeleteQuest = async () => {
    try {
      await deleteQuestWithSnackbar(id, quest.id);
    } finally {
      setIsOpenModalConfirmDelete(false);
    }
  };

  return (
    <Container>
      <Modal
        open={isOpenModalQuestForm}
        onClose={() => setIsOpenModalQuestForm(false)}
        title={titleModalQuestForm}
        loading={isLoadingCreateQuest || isLoadingEditQuest}
      >
        <QuestForm onClose={() => setIsOpenModalQuestForm(false)} onSubmit={quest ? onEditQuest : onCreateQuest} initialValues={quest} />
      </Modal>
      <ConfirmationModal
        loading={isLoadingDeleteQuest}
        open={isOpenModalConfirmDelete}
        onClose={() => setIsOpenModalConfirmDelete(false)}
        onConfirm={onDeleteQuest}
        title="form.quest.delete"
      />
      <QuestsContainer>
        <Typography variant="subtitle1" color="textSecondary">
          <FormattedMessage id="screen.challenge.quest.title" />
        </Typography>
        {[
          /* SORTING ARRAY BY TYPE */
          ...quests.filter((curQuest) => curQuest.type === QuestGoalType.PROGRESSIVE),
          ...quests.filter((curQuest) => curQuest.type === QuestGoalType.UNITARY),
        ].map((q: QuestType) => (
          <Quest key={q.id}>
            <QuestTitle>
              {q.type === QuestGoalType.UNITARY ? <CheckCircle size={20} /> : <BarChart2 size={20} />}
              {q.name} -
              {` ${intl.formatMessage({ id: `quest.type.${q.type}` })} - ${intl.formatMessage({
                id: `quest.account.${q.account ? "true" : "false"}`,
              })}`}
              {q.aggregation ? ` (${intl.formatMessage({ id: `quest.aggregation.${q.aggregation}` })})` : ""}
            </QuestTitle>
            {isAuthModeOKTA && (
              <ActionsContainer>
                <IconContainer
                  onClick={() => {
                    setQuest(q);
                    setIsOpenModalQuestForm(true);
                  }}
                >
                  <Edit3 color={colors.lightGrey} />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    setQuest(q);
                    setIsOpenModalConfirmDelete(true);
                  }}
                >
                  <Trash2 color={colors.lightGrey} />
                </IconContainer>
              </ActionsContainer>
            )}
          </Quest>
        ))}
      </QuestsContainer>
      {isAuthModeOKTA && (
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setQuest(undefined);
              setIsOpenModalQuestForm(true);
            }}
          >
            <FormattedMessage id="screen.challenge.quest.button.add" />
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default QuestBlock;

import React, { useEffect, useState } from "react";
import { Grid, Container as MuiContainer, Paper as MuiPaper, Typography, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Grow from "@material-ui/core/Grow";
import { styled as muiStyled } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import moment from "moment";
import { Edit3 } from "react-feather";

import AbsoluteLoader from "components/loader/AbsoluteLoader";
import actions from "store/actions";
import { spacing } from "assets/styles/spacing";
import Modal from "components/modal/Modal";
import { RootState } from "store/types";
import { ChallengeFormType } from "types/form.types";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";
import { colors } from "assets/styles/colors";
import SelectYear from "components/selects/SelectYear";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";

import { selectYear } from "store/challenge/challenge.actions";
import DateBlock from "./DateBlock";
import RulesBlock from "./RulesBlock";
import QuestBlock from "./QuestBlock";
import UserBlock from "./UserBlock";
import ChallengeForm, { ChallengeFormInitialValuesType } from "./ChallengeForm";

const Paper = muiStyled(MuiPaper)({
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  margin: 6,
  flex: 1,
});

const Container = muiStyled(MuiContainer)({
  flex: 1,
  marginBottom: 16,
});

const ContainerNoChallenge = styled.div`
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  color: white;
  font-size: 1.2rem;
`;

const IconContainer = styled.span`
  cursor: pointer;
  margin: 0 8px;

  &:hover > svg {
    stroke: ${colors.yellowRenault};
  }
`;

const ChallengeNameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonCreate = muiStyled(Button)({
  marginTop: spacing(8),
});

const Block = muiStyled(Grid)({});
const Challenge = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const year = useSelector((state: RootState) => state.challenge.selectedYear);
  const [isOpenModalChallengeForm, setIsOpenModalChallengeForm] = useState<boolean>(false);
  const isAuthModeOKTA = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) === AuthMode.OKTA;

  const defaultChallengeValues = {
    name: intl.formatMessage({ id: "form.challenge.title" }, { year }),
    startDate: moment().startOf("year"),
    endDate: moment().endOf("year"),
    registrationStartDate: moment().startOf("year"),
    registrationEndDate: moment().startOf("year"),
    closeDate: moment().endOf("year"),
    points: 4,
  };

  const { challenge, years, rulesLink } = useSelector((state: RootState) => state.challenge);
  const { resultsPublished, startDate, endDate, registrationStartDate, registrationEndDate, closeDate, id, name, quests, rulesFilename } =
    challenge || {};

  const getChallenge = (y: number) => dispatch(actions.challenge.getChallenge(y));
  const fetchRulesLink = (filename: string) => dispatch(actions.challenge.getChallengeRulesLink(filename));
  const createChallenge = (formValues: ChallengeFormType) => dispatch(actions.challenge.createChallenge(formValues));
  const updateChallenge = (challengeId: string, formValues: ChallengeFormType) =>
    dispatch(actions.challenge.updateChallenge(challengeId, formValues));

  const [isLoadingCreateChallenge, createChallengeWithSnackbar] = useSnackbarPromiseHandler(
    createChallenge,
    `server.success.${ServerSuccesses.CREATE_SUCCESS}`
  );
  const [isLoadingUpdateChallenge, updateChallengeWithSnackbar] = useSnackbarPromiseHandler(
    updateChallenge,
    `server.success.${ServerSuccesses.EDIT_SUCCESS}`
  );

  const onCreateChallenge = async (formValues: ChallengeFormInitialValuesType) => {
    try {
      await createChallengeWithSnackbar({
        ...formValues,
        startDate: formValues.startDate.format("yyyy-MM-DD"),
        endDate: formValues.endDate.format("yyyy-MM-DD"),
        registrationStartDate: formValues.registrationStartDate.format("yyyy-MM-DD"),
        registrationEndDate: formValues.registrationEndDate.format("yyyy-MM-DD"),
        closeDate: formValues.closeDate.format("yyyy-MM-DD"),
      });
    } finally {
      setIsOpenModalChallengeForm(false);
    }
  };

  const onUpdateChallenge = async (formValues: ChallengeFormInitialValuesType) => {
    try {
      await updateChallengeWithSnackbar(id, {
        ...formValues,
        startDate: formValues.startDate.format("yyyy-MM-DD"),
        endDate: formValues.endDate.format("yyyy-MM-DD"),
        registrationStartDate: formValues.registrationStartDate.format("yyyy-MM-DD"),
        registrationEndDate: formValues.registrationEndDate.format("yyyy-MM-DD"),
        closeDate: formValues.closeDate.format("yyyy-MM-DD"),
      });
    } finally {
      setIsOpenModalChallengeForm(false);
    }
  };

  useEffect(() => {
    (async function auth() {
      try {
        setLoading(true);
        await getChallenge(year);
        if (rulesFilename) {
          await fetchRulesLink(rulesFilename);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [year, rulesFilename]);

  return (
    <Container>
      {loading && <AbsoluteLoader />}
      {!loading && <SelectYear years={years} year={year} setYear={(y) => dispatch(selectYear(y))} paddingRight={9} />}
      {!loading &&
        (id ? (
          <>
            <Grid item container xs={12} justify="center">
              <Grid item container xs={5}>
                <Grid item container xs={12}>
                  <Grow in>
                    <Paper elevation={2}>
                      <Grid item container xs={12}>
                        <ChallengeNameContainer>
                          <Typography variant="h3" color="textPrimary">
                            {name}
                          </Typography>
                          {isAuthModeOKTA && (
                            <IconContainer onClick={() => setIsOpenModalChallengeForm(true)}>
                              <Edit3 color={colors.lightGrey} />
                            </IconContainer>
                          )}
                        </ChallengeNameContainer>
                      </Grid>
                      <Block item container xs={12} justify="center" direction="column">
                        <DateBlock
                          startDate={startDate}
                          id={id}
                          resultsPublished={resultsPublished}
                          endDate={endDate}
                          registrationStartDate={registrationStartDate}
                          registrationEndDate={registrationEndDate}
                          closeDate={closeDate}
                        />
                      </Block>
                    </Paper>
                  </Grow>
                </Grid>
                <Grid item container xs={12}>
                  <Grow in>
                    <Paper elevation={2}>
                      <RulesBlock challengeId={id} rulesFilename={rulesFilename} rulesLink={rulesLink} />
                    </Paper>
                  </Grow>
                </Grid>
              </Grid>
              <Grid item container xs={7}>
                <Grow in>
                  <Paper elevation={2}>
                    <QuestBlock quests={quests} />
                  </Paper>
                </Grow>
              </Grid>
              <Grid item container xs={12}>
                <Grid item container xs={12}>
                  <Grow in timeout={520}>
                    <Paper elevation={2}>
                      <UserBlock />
                    </Paper>
                  </Grow>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <ContainerNoChallenge>
            <FormattedMessage id="screen.challenge.no.challenge" values={{ year }} />
            <ButtonCreate variant="contained" color="primary" onClick={() => setIsOpenModalChallengeForm(true)}>
              <FormattedMessage id="screen.challenge.button.create" />
            </ButtonCreate>
          </ContainerNoChallenge>
        ))}
      <Modal
        open={isOpenModalChallengeForm}
        onClose={() => setIsOpenModalChallengeForm(false)}
        title="form.challenge.title"
        titleIntlValues={{ year }}
        loading={isLoadingCreateChallenge || isLoadingUpdateChallenge}
      >
        <ChallengeForm
          initialValues={
            id
              ? {
                  ...challenge,
                  startDate: moment(startDate),
                  endDate: moment(endDate),
                  registrationStartDate: moment(registrationStartDate),
                  registrationEndDate: moment(registrationEndDate),
                  closeDate: moment(closeDate),
                }
              : defaultChallengeValues
          }
          onClose={() => setIsOpenModalChallengeForm(false)}
          onSubmit={id ? onUpdateChallenge : onCreateChallenge}
        />
      </Modal>
    </Container>
  );
};

export default Challenge;

import axios from "axios";
import qs from "query-string";

import {
  AddContactRequest,
  AddResultsParamsType,
  GETAccount,
  GETChallenge,
  GETChallengeByYear,
  GETChallengeContacts,
  GETChallengeRulesLink,
  GETImportsHistory,
  GETOrderByContact,
  GETResultsSummary,
  GETStoreItems,
} from "types/api.types";
import {
  AccountsFilterFormType,
  AccountsFilterResponseType,
  AskResetPasswordForm,
  ChallengeFormType,
  QuestFormType,
  ResetPasswordForm,
  ResultsFilterFormType,
  ResultsFilterResponseType,
  SearchAccountResponse,
  SearchContactResponse,
} from "types/form.types";
import type { AccountListFilter, Challenge, QuestType } from "types/common.types";
import * as ENV from "config/env";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { getTokenSilently } from "utils/authentication.utils";
import { ImportDataType } from "enums/type.enums";

import endpoints from "./endpoints";

axios.defaults.baseURL = ENV.API_BASE_URL;
// 5 minutes
axios.defaults.timeout = 60000 * 5;

const ws = axios.create({ maxRedirects: 0 });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const makeRequestCreator = () => {
  let token: any;

  // eslint-disable-next-line consistent-return
  return (query: any): any => {
    // Check if we made a request
    if (token) {
      // Cancel the previous request before making a new request
      token.cancel();
    }
    // Create a new CancelToken
    token = axios.CancelToken.source();

    return ws({ ...query, cancelToken: token.token });
  };
};

const paramsSerializer = (params: any) => qs.stringify(params);

const api = {
  auth: {
    login: {
      byCredentials: async (login: string, password: string, token: string): Promise<any> =>
        ws.post(`${ENV.API_BASE_HOSTNAME}/public/api${endpoints.auth.login.credentials}`, { login, password, token }),
    },
    isAuthenticated: (): Promise<any> => ws.get(`${ENV.API_BASE_HOSTNAME}/public/api${endpoints.auth.isAuthenticated}`),
    askResetPassword: (form: AskResetPasswordForm): Promise<any> =>
      ws.post(`${ENV.API_BASE_HOSTNAME}/public/api${endpoints.auth.askResetPassword}`, form),
    resetPassword: (form: ResetPasswordForm): Promise<any> =>
      ws.post(`${ENV.API_BASE_HOSTNAME}/public/api${endpoints.auth.resetPassword}`, form),
  },
  challenge: {
    create: async (formValues: ChallengeFormType) => ws.post(endpoints.challenge.create, formValues),
    update: async (challengeId: string, formValues: ChallengeFormType) => ws.post(endpoints.challenge.update(challengeId), formValues),
    byYear: async (year: number): Promise<GETChallengeByYear> => ws.get(endpoints.challenge.byYear, { params: { year } }),
    getContacts: async (id: string): Promise<GETChallengeContacts> => ws.get(endpoints.challenge.contacts(id)),
    getRulesLink: async (rulesFilename: string): Promise<GETChallengeRulesLink> => ws.get(endpoints.challenge.rulesLink(rulesFilename)),
    uploadNewRules: async (challengeId: string, formData: FormData): Promise<GETChallenge> =>
      ws.post(endpoints.challenge.uploadNewRules(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    accounts: async (
      id: string,
      formValues: AccountsFilterFormType | {}
    ): Promise<{
      data: AccountsFilterResponseType;
    }> => ws.get(endpoints.challenge.accounts(id), { params: { ...formValues }, paramsSerializer }),
    account: async (id: string, challengeId: string): Promise<GETAccount> => ws.get(endpoints.challenge.account(id, challengeId)),
    deleteAccountContact: async (challengeId: string, accountId: string, contactId: string): Promise<GETAccount> =>
      ws.delete(endpoints.challenge.deleteAccountContact(challengeId, accountId, contactId)),
    addContact: async (challengeId: string, form: AddContactRequest): Promise<void> =>
      ws.post(endpoints.challenge.addContact(challengeId), form),
    deleteContact: async (challengeId: string, contactId: string): Promise<GETAccount> =>
      ws.delete(endpoints.challenge.deleteContact(challengeId, contactId)),
  },
  results: {
    filter: async (
      challengeId: string,
      formValues: ResultsFilterFormType | {}
    ): Promise<{
      data: ResultsFilterResponseType;
    }> => ws.get(endpoints.results.filter(challengeId), { params: { ...formValues }, paramsSerializer }),
    summary: async (challengeId: string): Promise<GETResultsSummary> => ws.get(endpoints.results.summary(challengeId)),
    adjudicate: async (challengeId: string, contactId: string, adjudication: boolean, comment?: string): Promise<{ data: boolean }> =>
      ws.post(endpoints.results.adjudicate(challengeId, contactId), { adjudication, comment }),
    registration: async (challengeId: string, contactId: string, registered: boolean): Promise<{ data: boolean }> =>
      ws.post(endpoints.results.registration(challengeId, contactId), { registered }),
    updateType: async (challengeId: string, contactId: string, contactType: string): Promise<{ data: string }> =>
      ws.post(endpoints.results.updateType(challengeId, contactId), { contactType }),
    addResultsAccountChallenge: async (
      { challengeId, accountId, questId }: AddResultsParamsType,
      formValues: Pick<QuestType, "done" | "goal">
    ): Promise<void> => ws.post(endpoints.results.addResultsAccountChallenge({ challengeId, accountId, questId }), formValues),
    addResultsContactChallenge: async (
      { challengeId, contactId, questId }: AddResultsParamsType,
      formValues: Pick<QuestType, "done" | "goal">
    ): Promise<void> => ws.post(endpoints.results.addResultsContactChallenge({ challengeId, contactId, questId }), formValues),
  },
  quest: {
    create: async (challengeId: string, form: QuestFormType): Promise<GETChallenge> => ws.post(endpoints.quest.create(challengeId), form),
    edit: async (challengeId: string, questId: string, form: QuestFormType): Promise<GETChallenge> =>
      ws.post(endpoints.quest.edit(challengeId, questId), form),
    delete: async (challengeId: string, questId: string): Promise<{ data: boolean }> =>
      ws.delete(endpoints.quest.delete(challengeId, questId)),
  },
  storeManagement: {
    getItems: async (challengeId: string): Promise<GETStoreItems> => ws.get(endpoints.storeManagement.getItems(challengeId)),
    openStore: async (challengeId: string, open: boolean): Promise<{ data: Challenge }> =>
      ws.post(endpoints.storeManagement.open(challengeId), { open }),
    publishResults: async (challengeId: string, published: boolean): Promise<{ data: Challenge }> =>
      ws.post(endpoints.storeManagement.publishResults(challengeId), { published }),
    notifyOpen: async (
      challengeId: string
    ): Promise<{
      data: boolean;
    }> => ws.post(endpoints.storeManagement.notifyOpen(challengeId)),
    update: async (challengeId: string, productId: string, formData: FormData) =>
      ws.post(endpoints.storeManagement.update(challengeId, productId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    delete: async (challengeId: string, productId: string): Promise<{ data: boolean }> =>
      ws.delete(endpoints.storeManagement.delete(challengeId, productId)),
    orderByContact: (challengeId: string, contactId: string): Promise<GETOrderByContact> =>
      ws.get(endpoints.storeManagement.orderByContact(challengeId, contactId)),
  },
  imports: {
    catalog: async (challengeId: string, formData: FormData) =>
      ws.post(endpoints.imports.catalog(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    media: async (challengeId: string, formData: FormData) =>
      ws.post(endpoints.imports.media(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    results: async (challengeId: string, formData: FormData) =>
      ws.post(endpoints.imports.results(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    users: async (challengeId: string, formData: FormData) =>
      ws.post(endpoints.imports.users(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    analyzeCatalog: async (challengeId: string, formData: FormData) =>
      ws.post(endpoints.imports.analyzeCatalog(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    analyzeMedia: async (challengeId: string, formData: FormData) =>
      ws.post(endpoints.imports.analyzeMedia(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    analyzeResults: async (challengeId: string, formData: FormData) =>
      ws.post(endpoints.imports.analyzeResults(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
    analyzeUsers: async (challengeId: string, formData: FormData) =>
      ws.post(endpoints.imports.analyzeUsers(challengeId), formData, { headers: { "Content-Type": "multipart/form-data" } }),
  },
  exports: {
    results: async (challengeId: string) =>
      ws.get(endpoints.exports.results(challengeId), {
        responseType: "blob", // important for download
      }),
    orders: async (challengeId: string) =>
      ws.get(endpoints.exports.orders(challengeId), {
        responseType: "blob", // important for download
      }),
    sampleCatalog: async (challengeId: string) =>
      ws.get(endpoints.exports.sampleCatalog(challengeId), {
        responseType: "blob", // important for download
      }),
    sampleResults: async (challengeId: string) =>
      ws.get(endpoints.exports.sampleResults(challengeId), {
        responseType: "blob", // important for download
      }),
    sampleUsers: async (challengeId: string) =>
      ws.get(endpoints.exports.sampleUsers(challengeId), {
        responseType: "blob", // important for download
      }),
  },
  history: {
    imports: async (challengeId: number, page: number, size: number, type?: ImportDataType): Promise<GETImportsHistory> =>
      ws.get(endpoints.history.imports(challengeId), {
        params: {
          page,
          size,
          type, // optional param
        },
      }),
  },
  contact: {
    filter: async (
      formValues: AccountListFilter | {}
    ): Promise<{
      data: SearchContactResponse[];
    }> => ws.get(endpoints.contact.filter, { params: { ...formValues }, paramsSerializer }),
  },
  account: {
    filter: async (
      formValues: AccountListFilter | {}
    ): Promise<{
      data: SearchAccountResponse[];
    }> => ws.get(endpoints.account.filter, { params: { ...formValues }, paramsSerializer }),
  },
};

ws.interceptors.request.use(async (request: any) => {
  const { url } = request;
  let token = "";

  /* Don't renew token if call renew */
  if (url.includes(endpoints.auth.login.credentials)) {
    token = localStorage.getItem(LOCAL_STORAGE.API_TOKEN);
  } else {
    token = await getTokenSilently();
  }

  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE);
  ws.defaults.headers.common = {
    Authorization: token,
  };

  if (request.headers) {
    request.headers.Authorization = `Bearer ${token}`;
    request.headers["x-authenticate-mode"] = authMode ?? "";
    request.headers["x-agent"] = "WEB";
  }

  return request;
});

/* FORCE IDP TOKEN (DEV/DEBUG USAGE ONLY) */
/*
ws.interceptors.request.use(async (request: any) => {
  ws.defaults.headers.common = {
    Authorization: ENV.DEV_TOKEN,
    "x-authenticate-mode": "IDP"
  };

  if (request.headers) {
    request.headers.Authorization = ENV.DEV_TOKEN;
    request.headers["x-authenticate-mode"] = "IDP";
  }

  return request;
});
*/

ws.interceptors.response.use(
  async (response: any) => response,
  async (error: any) => {
    if (axios.isCancel(error)) {
      return;
    }

    if (
      error.response &&
      ((error.response.data && error.response.data.status && error.response.data.status === 401) ||
        (error.response.status && error.response.status === 401))
    ) {
      /* If not logged, save current uri and reset apiToken */
      localStorage.setItem(LOCAL_STORAGE.REDIRECT_URL, window.location.pathname);
      localStorage.removeItem(LOCAL_STORAGE.API_TOKEN);

      window.dispatchEvent(new CustomEvent("resetAuth"));
    }

    throw error;
  }
);

export default api;

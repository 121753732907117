import api from "api";

import { AuthMode } from "enums/api.enums";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";

import { ACTION_TYPES, AuthActionTypes } from "./auth.types";

export const resetAuth = (): AuthActionTypes => ({
  type: ACTION_TYPES.AUTH_RESET,
});

/* User to update components that depends on local storage apiToken */
export const authenticateUser = (apiToken: string, authMode: AuthMode) => async (dispatch: any) => {
  localStorage.setItem(LOCAL_STORAGE.API_TOKEN, apiToken);
  localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, authMode);

  dispatch({
    type: ACTION_TYPES.LOGIN_USER,
    payload: { apiToken, authMode },
  });
};

/* User to update components that depends on local storage apiToken */
/* Return contactId */
export const loginByCredentials = (login: string, password: string, token: string) => async (dispatch: any) => {
  const { data } = await api.auth.login.byCredentials(login, password, token);
  const apiToken = `${data.token}`;
  const authMode = AuthMode.CLUB;
  localStorage.setItem(LOCAL_STORAGE.API_TOKEN, apiToken);
  localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, authMode);

  dispatch({
    type: ACTION_TYPES.LOGIN_USER,
    payload: { apiToken, authMode },
  });

  return data;
};

export const isAuthenticated = () => async (dispatch: any) => {
  const payload = await api.auth.isAuthenticated();

  return dispatch({
    type: ACTION_TYPES.LOGIN_USER,
    payload: payload.data,
  });
};
